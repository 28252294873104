import React, {Component} from "react"


import Layout from "../components/layout"
// import SEO from "../components/seo"
import TitleHeader from "../components/TitleHeader";

class Blog extends Component {
    render() {
      return (
        <Layout>
          <TitleHeader title="Blog" />
          <div className="content">
            <h1>Oczekiwanie na materiały</h1>
          </div>
        </Layout>
      );
    }
  }
  
  export default Blog;
